import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { mq, elevation, offset, offsetXxl, gutter } from "../utils/presets"


const ButtonBox = styled.a`
    ${mq.mobile} {
        color: #6b6a66;
        border-color: #6b6a66;
    }
    height: 35px;
    width: 33%;
    text-align: center;
    position: sticky;
    position: -webkit-sticky;
    top: 2.5vw;
    border-radius: 12px;
    text-decoration: none;
    float: right;

    background-color: transparent;
    border: 3px solid #D3D3D3;
    color: #D3D3D3;
    cursor: pointer;
    display: inline-block;
    min-width: 10em;
    padding: 0 1.5em;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    &:hover {
        color: #e18585;
        border-color: #e18585;

`
const ButtonText = styled.div`
    width: 100%;
	text-align: center;
    font-size: 30px;
    font-weight: bold;
`
const Top = () => (
    <ButtonBox href="#top">
        <ButtonText>
            Top 
        </ButtonText>
    </ButtonBox>
)

export default Top 
